import axios from "axios";
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";



export function SiteHeader () {
	const [siteName, setSiteName] = useState([]);
	
	useEffect(() => {
    	axios.get(`https://api.contentful.com/spaces/dmrbsct292q8/?access_token=CFPAT-TovSZS0LyofpeIS8Q75YvaxrhNjLazYi89Lbsd2wvxs`).then((response) => {
      		setSiteName(response.data.name);
    	});
  	}, []);
	return (
		<div className="bg-slate-200 shadow-lg sticky top-0">
	    	<div className="container mx-auto px-9 py-3" >
	    		<h1 className="font-mono text-5xl"><Link to="/">{siteName}</Link></h1>
	    	</div>
	    </div>
  )
}