import axios from "axios";
import React, { useState, useEffect } from 'react';
import {EventItem} from './Event.jsx';

export function EventsGrid () {
	const [events, setEvents] = useState([]);

	useEffect(() => {
    	axios.get(`https://api.contentful.com/spaces/dmrbsct292q8/environments/master/entries/?access_token=CFPAT-TovSZS0LyofpeIS8Q75YvaxrhNjLazYi89Lbsd2wvxs`).then((response) => {
    		console.log(response);
      		setEvents(response.data.items);
    	});
  	}, []);

	return (
    	<div className="container mx-auto px-9" >
    		<div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4" >

     			{events.map( event => {
     				return ( 
     					event.fields && event.fields.slug && event.fields.eventTitle && <EventItem {...event} key={event.sys.id} slug={event.fields.slug['en-US']} title={event.fields.eventTitle['en-US']} 
     					imageId={event.fields.eventBanner && event.fields.eventBanner['en-US'].sys.id} shortDescription={event.fields.eventShortDescription['en-US']} location={event.fields.eventLocation['en-US']} dateTime={event.fields.eventDate['en-US']} url={event.fields.eventUrl && event.fields.eventUrl['en-US']} 
     					/>
            		)
            		
     			})}
    		</div>
    	</div>
  )
}
