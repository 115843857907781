import logo from './logo.svg';
import { Routes, Route } from "react-router-dom";
import './App.css';
import {EventsGrid} from './Feed.jsx';
import {EventPage} from './Event_Single.jsx';
import {SiteHeader} from './Header.jsx';
import {SiteFooter} from './Footer.jsx';
import {NotFound} from './NotFound.jsx';


function App() {
  return (
    <>
    <SiteHeader />
    <h1 className="m-20 font-mono text-5xl text-center">My <span className="bg-slate-900 text-white">Events</span></h1>
    <Routes>
        <Route path="/" element={<EventsGrid />} />
        <Route path="/events" >
          <Route index element={<EventsGrid />} />
          <Route path=":slug" element={<EventPage />} />
        </Route>
        <Route path='*' element={<NotFound/>} />
    </Routes>
    <SiteFooter />
    </>
  );
}

export default App;
