import { Link } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";
import moment from 'moment';


export function EventItem ({slug, title, shortDescription, location, url, dateTime, imageId}) {
	const [imageUrl, setImageUrl] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

     	axios.get(`https://api.contentful.com/spaces/dmrbsct292q8/environments/master/assets/${imageId}?access_token=CFPAT-TovSZS0LyofpeIS8Q75YvaxrhNjLazYi89Lbsd2wvxs`).then((response) => {
			setImageUrl(response.data.fields.file['en-US'].url);
			setIsLoading(false);
			console.log(url)
		});
	return (
			<SkeletonTheme baseColor="#f3f3f3" highlightColor="#d3d3d3">
			<div className={isLoading ? 'animate-pulse ' : null}>
				<div className="overflow-hidden border rounded shadow-md bg-gray-100 mb-8 fadeIn">
					<img className="" src={imageUrl} style={{ aspectRatio: 3/2 }}/>
					
					<h3 className="p-3 text-lg text-center bg-slate-200">{isLoading ? <Skeleton count={1} height="40px"/> : title}</h3>
					<h4 className="p-3 text-center text-md">
						<ul className="list-none">
							{isLoading ? <Skeleton count={1} height="20px"/> : <li><strong>Location: {location}</strong> </li>}
							{isLoading ? <Skeleton count={1} height="20px"/> :<li><strong>Date/Time: {moment(dateTime).format('MMMM Do YYYY, h:mm:ss a')}</strong> </li>}
						</ul>
					</h4>
					{isLoading ? '' :<p className="text-center pt-3 py-6">{shortDescription}</p> }
					{!isLoading && url && <Link className="p-3 text-lg text-center text-white bg-red-500 w-full block" to={url}>RSVP</Link>}
					{!isLoading && <Link className="p-3 text-lg text-center text-white bg-sky-700 w-full block" to={'/events/' + slug }>View</Link>}
				</div>
			</div>
			</SkeletonTheme>
	)
}