import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { useState , useEffect } from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";
import moment from 'moment'

export function EventPage () {
	const { slug } = useParams()
	//const [eventContent, setEventContent] = useState([]);
	const [eventData, setEventData] = useState([]);
	//const [imageId, setImageId] = useState([]);
	const [imageData, setImageData] = useState([]);

	useEffect(() => {
    	axios.get(`https://api.contentful.com/spaces/dmrbsct292q8/environments/master/entries/?content_type=event&fields.slug=${slug}&access_token=CFPAT-TovSZS0LyofpeIS8Q75YvaxrhNjLazYi89Lbsd2wvxs`).then((response) => {
      		setEventData(response.data.items[0]);
      		axios.get(`https://api.contentful.com/spaces/dmrbsct292q8/environments/master/assets/${response.data.items[0].fields.eventBanner['en-US'].sys.id}?access_token=CFPAT-TovSZS0LyofpeIS8Q75YvaxrhNjLazYi89Lbsd2wvxs`).then((res) => {
      			setImageData(res.data.fields.file['en-US'])
      		})
    	});
  	}, []);


  	return (
  		<>
  		<SkeletonTheme baseColor="#f3f3f3" highlightColor="#d3d3d3">
	  		<div className="container mx-auto px-9 flex flex-wrap" >
	  		<Link to='/'><strong>&lt; Back to Main Page</strong></Link>
	  		<h2 className="m-10 text-4xl text-center w-full"><strong>{ eventData.fields ? eventData.fields.eventTitle['en-US'] :  <Skeleton className="mx-auto max-w-80" count={1} height="40px"/> }</strong></h2>
	  			<div className="lg:w-3/4 p-3 ">
	  				{ imageData && 
		  				<div className = "mx-auto max-w-screen-lg mb-12">
		  					<img className="rounded-lg border-slate-300 mb-8	" src={imageData.url } width={imageData.details && imageData.details.image && imageData.details.image.width} height={imageData.details && imageData.details.image && imageData.details.image.height}
		  					/> 
		  				</div>
		  			}


		     		<p className="mb-20">
		     			{eventData.fields ? eventData.fields.eventDescription['en-US'].content[0].content[0].value : <Skeleton count={20} height="20px"/>}
		     			
		     		</p>  
	     		</div>
	     		<div className="lg:w-1/4 p-3  ">
	     			<div className="p-3 border rounded shadow-md bg-gray-100">
		     			<ul className="list-none text-xl">
							{ ( eventData.fields && eventData.fields.eventLocation['en-US']) ?  <li className="mb-6"><strong>Location:</strong> {eventData.fields.eventLocation['en-US']} </li> : <Skeleton count={1} height="20px"/> }
							{ (eventData.fields && eventData.fields.eventDate) ?  <li className="mb-6"><strong>Date/Time:</strong> {moment(eventData.fields.eventDate).format('MMMM Do YYYY, h:mm:ss a')} </li> : <Skeleton count={1} height="20px"/>}
							{ (eventData.fields && eventData.fields.eventUrl && eventData.fields.eventUrl['en-US']) && <Link className="p-3 text-lg text-center text-white bg-red-500 w-full block" to={eventData.fields.eventUrl['en-US']}>RSVP</Link>}
						</ul>
					</div>
	     		</div>
	        </div>
	    </SkeletonTheme>
  		</>
  		)
}